import React from 'react';

import IconButton from '@mui/material/IconButton';
import Loader from 'src/ui/components/Loader';
import Toastify from 'src/ui/containers/Toastify';
import MainSidebar from 'src/ui/containers/MainSidebar/MainSidebar';
import HrmSidebar from 'src/ui/containers/HrmSidebar/HrmSidebar';
import Router from 'src/ui/containers/Router';
import Protector from 'src/ui/components/Protector';
import PushNotifications from 'src/ui/containers/PushNotifications';

import { useAppDispatch, useAppSelector } from 'src/store';
import { mainSliceThunks } from 'src/store/mainSlice';
import images from './ui/assets/images';
import './api/socket';
import { UserPermissionsENUM } from 'src/types';
import { useNewChatMessageSound } from './ui/pages/Chat/Chat.hooks';
import useAppBadge from './utils/hooks/useAppBadge';
import { useMessagingInterface } from './utils/messagingInterface';
import useNotificationClick from './utils/hooks/useNotificationClick';
import useWindowReloadAfterSleep from './utils/hooks/useWindowReloadAfterSleep';
import WithTitleBar from './ui/containers/TitleBar/WithTitleBar';
import { ElectronAPI } from './utils/electronAPI';
import InitAppData from './InitAppData';

const App: React.FC = () => {
  const isAuthChecked = useAppSelector(({ main }) => main.isAuthChecked);
  const revalidateAppDataTrigger = useAppSelector(({ main }) => main.revalidateAppDataTrigger);

  const dispatch = useAppDispatch();

  const appInitializerKey = React.useMemo(() => {
    if (ElectronAPI.isElectronApp) {
      return Math.random().toFixed(3);
    }
    return 'init-data';
  }, [revalidateAppDataTrigger]);

  useMessagingInterface();
  useAppBadge();

  React.useEffect(() => {
    ElectronAPI?.events?.onAppUpdatesReady<string>((data) => {
      console.info('>>>APP READY FOR UPDATES<<<', data);

      ElectronAPI?.actions?.callUpdateAppModal({
        message: `Доступно новое обновление. ${data}. Обновить сейчас?`,
        title: 'Обновление',
      });
    });
    dispatch(mainSliceThunks.authorize());
  }, [dispatch]);

  useNewChatMessageSound();

  useNotificationClick();

  useWindowReloadAfterSleep();

  if (!isAuthChecked) {
    return <Loader isFixed />;
  }

  return (
    <>
      <InitAppData key={appInitializerKey} />
      <WithTitleBar disabled={!ElectronAPI.isElectronApp}>
        <Toastify
          autoClose={3000}
          draggable
          newestOnTop
          hideProgressBar
          closeButton={({ closeToast }) => (
            <IconButton onClick={closeToast}>
              <images.CloseIcon />
            </IconButton>
          )}
        />

        <>
          <PushNotifications />
          <MainSidebar />
          <Protector permissions={[UserPermissionsENUM.hrm__available]}>
            <HrmSidebar />
          </Protector>
        </>

        <Router />
      </WithTitleBar>

    </>
  );
};

export default App;
