import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import _uniqueId from 'lodash/uniqueId';
import { toast } from 'react-toastify';
import type { NavigateFunction } from 'react-router-dom';
import type { TFunction } from 'react-i18next';

import chatApi from 'src/api/chatApi';
import mediaApi from 'src/api/mediaApi';
import { convertFileToBase64 } from 'src/utils/fileUtils';
import { chatSliceActions } from './Chat.reducer';
import errorCodes from 'src/utils/errorCodes';
import { ROUTES } from 'src/utils/constants';
import type { GetParamsType } from 'src/api/http/http.types';
import type { IChannel, IMessage, IUser, MessageType } from 'src/types';
import type { ChannelType, DeleteMediaItemArgsType, IMedia, MediaInfoType, MediaLocalType } from 'src/types/chatTypes';
import isSupportedImage from 'src/ui/pages/Chat/utils/checkIsImage';
import type { AppDispatchType, AppStateType } from 'src/store/store';
import socket from 'src/api/ws/socket';
import socketConstants from 'src/api/socket/constants';

const getChannelsToOverview = createAsyncThunk(
  'chat/getChannelsToOverview',
  async (params: GetParamsType) => {
    const response = await chatApi.getChannelSearchResults(params);
    const channelsToOverview = response.data;
    return channelsToOverview;
  },
);

const getArchivalChannels = createAsyncThunk<IChannel[] | null>(
  'chat/getUserArchivalChannels',
  async () => {
    const response = await chatApi.getArchivalChannel();
    const userChannels = response.data.payload;
    return userChannels;
  },
);

const getSearchResults = createAsyncThunk(
  'chat/getSearchResults',
  async (params: {
    users: GetParamsType;
    channels: GetParamsType;
    messages: GetParamsType;
  }) => {
    const usersPromise = chatApi.getUserSearchResults(params.users);
    const channelsPromise = chatApi.getChannelSearchResults(params.channels);
    const messagesPromise = chatApi.getMessageSearchResults(params.messages);
    const promises = [usersPromise, channelsPromise, messagesPromise];

    const [
      usersResponse,
      channelsResponse,
      messagesResponse,
    ] = await Promise.all(promises);

    const searchResults = {
      users: usersResponse.data.payload as IUser[],
      usersCount: usersResponse.data.meta.totalRecords,
      channels: channelsResponse.data.payload as IChannel[],
      channelsCount: channelsResponse.data.meta.totalRecords,
      messages: messagesResponse.data.payload as IMessage[],
      messagesCount: messagesResponse.data.meta.totalRecords,
    };

    return searchResults;
  },
);

const getUsersSearchResults = createAsyncThunk(
  'chat/getUsersSearchResults',
  async (params: GetParamsType) => {
    const response = await chatApi.getUserSearchResults(params);

    const userSearchResults = {
      users: response.data.payload,
      usersCount: response.data.meta.totalRecords,
    };

    return userSearchResults;
  },
);

const getChannelsSearchResults = createAsyncThunk(
  'chat/getChannelsSearchResults',
  async (params: GetParamsType & { withPagination?: boolean; showMyChannels?: boolean }) => {
    const response = await chatApi.getChannelSearchResults(params);

    const channelsSearchResults = {
      channels: response.data.payload,
      channelsCount: response.data.meta.totalRecords,
    };

    return channelsSearchResults;
  },
);

const getMessagesSearchResults = createAsyncThunk(
  'chat/getMessagesSearchResults',
  async (params: GetParamsType) => {
    const response = await chatApi.getMessageSearchResults(params);

    const messagesSearchResults = {
      messages: response.data.payload,
      messagesCount: response.data.meta.totalRecords,
    };

    return messagesSearchResults;
  },
);

type UploadFilesParamsType = {
  files: MediaInfoType;
  isDiscussion?: boolean;
  t: TFunction<('chat' | 'errors')[], undefined>;
  messageId?: number;
  onUpload?: (messageData: MessageType) => void;
  channelId: number;
};

const uploadFiles = createAsyncThunk<void, UploadFilesParamsType,
  { dispatch: AppDispatchType; state: AppStateType }>(
    'chat/uploadFiles',
    async (params: UploadFilesParamsType, { dispatch, getState }) => {
      const uploadImage = async (
        media: MediaLocalType,
        messageId: number | null,
      ) => {
        const base64 = await convertFileToBase64(media.file);

        const data = {
          name: media.file.name,
          file: base64,
          isPrivate: true,
        };

        const config = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            handleLoadingInfoChange(
              media.mediaInfoId,
              messageId,
              {
                uploaded: progressEvent.loaded,
                total: progressEvent.total,
              },
              params.channelId,
            );
          },
        };
        const result = await mediaApi.uploadImage(data, config);
        handleUploadedMediaItem(media.mediaInfoId, messageId, result.data.payload);
      };

      const uploadFile = async (
        media: MediaLocalType,
        messageId: number | null,
      ) => {
        if (media.isError) {
          return;
        }
        const mimeTypeBody = media.file.type;
        const presignedUrlParams = { fileName: _uniqueId(), mimeType: mimeTypeBody };
        const link = await mediaApi.getPresignedLink(presignedUrlParams);
        const linkArr = link.data.payload.split('/');
        const companyId = linkArr[3];
        const name = linkArr[4].split('?')[0];
        const key = `${companyId}/${name}`;

        const config = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            handleLoadingInfoChange(
              media.mediaInfoId,
              messageId,
              {
                uploaded: progressEvent.loaded,
                total: progressEvent.total,
              },
              params.channelId,
            );
          },
          headers: {
            'Content-Type': media.file.type,
          },
        };
        const data = media.file;
        await axios.put(link.data.payload, data, config);
        const mediaItemData = {
          mimeType: media.file.type,
          name: media.file.name,
          key,
          isPrivate: true,
        };
        const mediaItem = await mediaApi.saveMediaItem(mediaItemData);
        handleUploadedMediaItem(media.mediaInfoId, messageId, mediaItem.data.payload);
      };

      const handleLoadingInfoChange = (
        id: string,
        messageId: number | null,
        uploadingProgress: { uploaded: number; total: number },
        channelId: number,
      ) => {
        const data = { id, messageId, channelId, ...uploadingProgress };
        const action = params.isDiscussion
          ? chatSliceActions.changeDiscussionMediaLoadingInfo
          : chatSliceActions.changeChatMediaLoadingInfo;

        dispatch(action(data));
      };

      const handleUploadedMediaItem = (
        uploadedMediaInfoId: string, messageId: number | null, uploadedMedia: IMedia,
      ) => {
        const data = { uploadedMedia, uploadedMediaInfoId, channelId: params.channelId };
        const action = params.isDiscussion
          ? chatSliceActions.addDiscussionMediaItem
          : chatSliceActions.addChatMediaItem;

        dispatch(action(data));

        if (messageId) {
          dispatch(chatSliceActions.updateUploadedMedia({
            messageId,
            value: data,
          }));
        }
      };

      const loadingMediaAction = params.isDiscussion
        ? chatSliceActions.setIsLoadingDiscussionMedia
        : chatSliceActions.setIsLoadingChatMedia;

      const messageId = params.messageId || null;
      const mediaFiles = Object.values(params.files);
      try {
        dispatch(loadingMediaAction(true));

        await Promise.all(mediaFiles.map(async (mediaFile) => {
          if (mediaFile.mediaItem || mediaFile.isError) {
            return;
          }
          const isImage = mediaFile.file.type.startsWith('image');
          const isCurrentImageSupported = isImage && isSupportedImage(mediaFile.file.type);

          const uploadFunction = (isImage && isCurrentImageSupported)
            ? uploadImage : uploadFile;

          await uploadFunction(mediaFile, messageId);
        }));
      } catch (err) {
        toast.error(params.t(errorCodes.chat.loadingFilesError));
        if (!params.isDiscussion) {
          dispatch(chatSliceActions.setChatMediaObject({ channelId: params.channelId, mediaInfo: null }));
        } else {
          dispatch(chatSliceActions.setDiscussionMediaList(null));
        }
      } finally {
        dispatch(loadingMediaAction(false));

        if (messageId !== null && params.onUpload) {
          const messageWithMediaToSend = getState().chatPage.messages[messageId];
          params.onUpload(messageWithMediaToSend);
        }
      }
    },
  );

const deleteMediaItem = createAsyncThunk(
  'chat/deleteMediaItem',
  async (args: DeleteMediaItemArgsType, { dispatch, getState }) => {
    try {
      await mediaApi.deleteFile(args.mediaItemId);
      dispatch(chatSliceActions.deleteMediaItem({
        mediaItemId: args.mediaItemId,
        messageId: args.messageId,
        mode: args.mode,
      }));

      const message = (getState() as AppStateType).chatPage.messages[args.messageId];

      const isEmptyText = !message?.messageText ||
        !message?.messageText?.length ||
        !message?.messageText[0]?.text.length;
      const hasMediaItems = message.media?.find((item) => !item.deletedAt);

      if (isEmptyText && !hasMediaItems && message?.channel?.channelId) {
        dispatch(chatSliceActions.setCurrentMediaForDelete(null));
        dispatch(chatSliceActions.setEditingChatMessage(null));
        dispatch(chatSliceActions.deleteMessage({
          deletedAt: Date.now().toString(),
          messageId: message.messageId,
          channelId: message.channel.channelId,
        }));

        await socket.emit(socketConstants.DELETE_MESSAGE, {
          channelId: message.channel.channelId,
          messageId: message.messageId,
        });
      }
    } catch (err) {
      toast.error(args.t(errorCodes.chat.loadingFilesError));
    } finally {
      dispatch(chatSliceActions.setCurrentMediaForDelete(null));
      dispatch(chatSliceActions.setCurrentFullImageMediaItem(null));
    }
  },
);

const readMessage = createAsyncThunk(
  'chat/readMessage',
  async (args: {
    messageId: number;
    channelId: number;
    withMention: boolean;
  }, { dispatch }) => {
    try {
      if (args.withMention) {
        dispatch(chatSliceActions.decrementMentionsCount({ chatId: args.channelId }));
      }
      dispatch(chatSliceActions.decrementUnreadMessagesCount({ chatId: args.channelId }));

      const params = {
        messageId: args.messageId,
        channelId: args.channelId,
      };

      const response = await chatApi.readMessage(params);
      dispatch(chatSliceActions.setLastViewedMessageForUser({
        channelId: args.channelId,
        userId: response.data.payload.userId,
        lastViewed: response.data.payload.lastViewedMessageTime,
      }));
    } catch (err) {
      // todo error
    }
  },
);

const checkAndNavigateToChannel = createAsyncThunk(
  'chat/checkAndNavigateToChannel',
  async (
    args: {
      chatId: string;
      userId: number;
      allChannels: {
        [id: number]: ChannelType;
      };
      navigate: NavigateFunction;
      t: TFunction<('chat' | 'errors')[]>;
    },
    { dispatch },
  ) => {
    const firstChannel = Object.keys(args.allChannels)[0];
    const defaultChannel = +firstChannel;

    try {
      if (!args.chatId) {
        args.navigate(ROUTES.chat.createPath(defaultChannel));
        if (!firstChannel) {
          dispatch(chatSliceActions.setIsShowCreatingChat(true));
        }
        return;
      }

      let currentChannel = args.allChannels[+args.chatId];
      if (!currentChannel) {
        const response = await chatApi.getChannelWithPinned(+args.chatId);
        currentChannel = response.data.payload;
        const userExistInChannel = currentChannel.userToChannels?.find((userToChannel) => {
          return userToChannel?.userId === args.userId;
        });
        if (currentChannel.isPrivate && !userExistInChannel) {
          args.navigate(ROUTES.chat.createPath(defaultChannel));
          dispatch(chatSliceActions.setIsShowCreatingChat(true));
        }
        dispatch(chatSliceActions.addChannel(currentChannel));
      }
      dispatch(chatSliceActions.setChatName(currentChannel.name));
      dispatch(chatSliceActions.setCurrentChatInfo({
        id: currentChannel.channelId,
        type: currentChannel.type,
        name: currentChannel.name,
      }));
    } catch (err) {
      args.navigate(ROUTES.chat.createPath(defaultChannel));
      toast.error(args.t(errorCodes.server.internalUnexpected));
    }
  },
);

export default {
  getArchivalChannels,
  getSearchResults,
  getUsersSearchResults,
  getChannelsSearchResults,
  getMessagesSearchResults,
  getChannelsToOverview,
  uploadFiles,
  deleteMediaItem,
  readMessage,
  checkAndNavigateToChannel,
};
